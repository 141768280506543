.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../../../../src/assets/image/background.jpg");
  background-size: cover;
  background-position: center;
  padding: 20px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(white, 0.5);
    z-index: 0;
  }

  .login-component {
    @media (max-width: 649px) {
      .description {
        display: none;
      }
    }
  }

  @media (max-width: 998px) {
    .login-component {
      width: 100%;
    }
  }
}
