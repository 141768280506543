.add-media-modal {

  .sub-title {
    font-size  : 1.2rem;
    user-select: none;
    text-align : left;
    font-weight: 600;
  }

  .select-day {
    display    : flex;
    margin-left: 2rem;
  }

  .content {
    height   : 60vh;
    min-width: 400px;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid white
  }
  .MuiIconButton-root {
    color: white;
  }
  .MuiInputBase-input {
    color: white;
  }
}