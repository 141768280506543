.before-loading-overlay {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &:after {
    position: absolute;
    display: flex;
    content: "";
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-page {
  display: flex;
  height: 100vh;

  .action-button {
    cursor: pointer;
    margin: 2px;
  }

  .left-box {
    flex: 2;

    .left-panel {
      padding: 0.5rem;

      .programs-component {
        display: flex;
        flex-direction: column;

        .react-calendar {
          width: 100%;

          .react-calendar__navigation {
            background-color: var(--primary-color);
          }
        }
      }

      .pad-tab {
        display: flex;
        flex-direction: column;
      }

      .link-item {
        display: flex;
        flex-direction: column;
        padding: 5px 0 5px 5px;
        cursor: pointer;
        user-select: none;
        font-weight: 700;
        margin: 5px 0;
        font-size: 1.1rem;

        .label {
          position: relative;
          border-bottom: 2px solid #eee;
          display: flex;
          padding: 5px;
          align-items: center;

          &.selected {
            background-color: var(--primary-color);

            border-bottom: none;

            .appened-fix {
              color: white;
            }
          }

          .appened-fix {
            position: absolute;
            right: 1rem;
            top: 0;
            color: var(--primary-color);
          }
        }

        .sub-link-items {
          padding-left: 15px;
        }
      }
    }
  }

  .center-box {
    flex: 8;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .input-form {
        flex: 1;
        padding-right: 20px;
      }

      .actions {
        display: flex;
        justify-content: flex-end;

        .panda-button + .panda-button {
          margin-left: 1rem;
        }
      }
    }

    .search {
      margin-top: 2rem;
    }

    .datatable {
      min-height: 500px;

      table {
        tr {
          td {
            .detail-button {
              cursor: pointer;
            }

            .actions {
              display: flex;
              flex-direction: row;
              justify-content: center;
            }

            .title {
              display: block;
            }

            &.empty-data {
              font-size: 1.2rem;
              text-align: center;
              height: 300px;
            }
          }
        }
      }

      .live-component {
        margin-top: 20px;
        display: flex;

        .preview {
          display: flex;
          align-items: center;
          justify-content: center;

          .no-preview {
            font-size: 1.5rem;
          }
        }

        & > div {
          flex: 1;
          margin: 1rem;
        }

        .detail {
          border: 1px solid var(--primary-color);
          border-radius: 5px;
          display: flex;
          position: relative;

          & > div {
            margin: 1rem;
            flex: 1;
          }

          .live-stream {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.4rem;
            top: -3rem;
            width: 100%;
            margin: 0 auto;
            text-align: center;
          }

          ul {
            list-style: none;
            margin-top: 2rem;
            padding: 0;

            li {
              display: flex;
              justify-content: space-between;
              margin: 1rem 0;
            }
          }

          .content {
            min-height: 220px;
          }

          .actions {
            display: flex;
            position: absolute;
            bottom: 0;
            justify-content: space-evenly;
            min-width: 300px;

            .live-button {
              width: 50px;
              padding: 5px;
              background-color: var(--primary-color);
              cursor: pointer;
              border-radius: 5px;

              &.disabled {
                background-color: grey;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .card-items {
      display: flex;

      .card {
        display: flex;
        text-align: center;
        background-color: var(--primary-color);
        border: none;
        // margin          : 1rem;
        font-size: 2.5rem;
        font-weight: 600;
        justify-content: center;
        padding: 10px;

        &.subject {
          flex: 1;

          div:first-of-type {
            font-size: 1.2rem;
          }

          div:last-of-type {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .right-box {
    flex: 2;
    padding: 0.5rem;

    .right-panel {
      .subject {
        font-size: 1.4rem;
        text-align: center;
        font-weight: 600;
        margin: 1rem 0;

        &.show-overlay {
          cursor: pointer;

          &:hover {
            background-color: #eee;
          }
        }
      }

      .overlay-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 800;
        margin: 1rem 0;
        box-shadow: 2px 4px 8px 2px #ccc;
        cursor: pointer;
        user-select: none;

        & > div {
          margin-left: 2rem;
        }

        &.text {
          background-color: #8b7374;
        }

        &.image {
          background-color: #b5ac8f;
        }
      }

      .image-button {
        margin: 1rem 0;
        cursor: pointer;
        box-shadow: 2px 4px 8px 2px #ccc;

        img {
          width: 100%;
        }
      }
    }

    .left-touch {
      display: none;
    }

    .overlay {
      display: none;
    }
  }

  .left-touch,
  .right-touch {
    display: none;
  }

  @media (max-width: 899px) {
    width: 100vw;

    .desktop {
      display: none;
    }

    .overlay.active {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }

    .left-touch,
    .right-touch {
      cursor: pointer;
      display: block;
      position: fixed;
      top: calc((100vh - 1rem) / 2);
      font-size: 2rem;
      padding: 0 0.5rem;
      border-radius: 0 5px 5px 0;
      background: var(--primary-color);
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      z-index: 999;
    }

    .right-touch {
      border-radius: 5px 0 0 5px;
      right: 0;
    }

    .left-box,
    .right-box {
      transition-duration: 0.5s;
      left: -50vw;
      overflow: auto;
      display: block;
      position: fixed;
      height: 100vh;
      width: 50vw;
      background: white;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      z-index: 1001;

      &.active {
        left: 0;
      }
    }

    .right-box {
      left: 100%;

      &.active {
        left: 50%;
      }
    }
  }

  @media (max-width: 649px) {
    .overlay.active {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }

    .left-box,
    .right-box {
      width: 80vw;
    }

    .left-box {
      left: -80vw;
    }

    .right-box {
      left: 100vw;

      &.active {
        left: 20vw;
      }
    }

    .center-box {
      width: 100vw;

      .header {
        flex-direction: column;

        .actions {
          margin-top: 1rem;
          justify-content: space-evenly;
        }
      }

      .card-items {
        flex-direction: column;

        .card {
          font-size: 1.5rem;
          // margin   : 0.5rem;
        }
      }

      .live-component {
        flex-direction: column;
      }

      .wrap-content {
        max-width: 100px;
      }
    }
  }
}
