.face-book-live {
  background-color: #655DD1;
  padding         : 0.5rem;
  border-radius   : 5px;

  .title {
    font-size  : 1.5rem;
    display    : flex;
    user-select: none;
    color      : white;

    .icon {
      display         : flex;
      justify-content : center;
      align-items     : center;
      border          : 1px solid #ccc;
      border-radius   : 50%;
      width           : 40px;
      height          : 40px;
      background-color: rgba(white, 0.3);
      margin-right    : 1rem;
    }

  }

  .actions {
    display        : flex;
    justify-content: center;
  }
}